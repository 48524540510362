import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/Gamerji_Text_logo_With_Tagline_White.png'
import { getLanguage } from "../../Utilities/controller";
import i18next from "i18next";
import { t } from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const Header = () => {

  const navigate = useNavigate()
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
      localStorage.getItem("GJLang") || Cookies.get("GJLang") || "English"
  );

  const currentLang = localStorage.getItem("GJLangCode") || Cookies.get("GJLangCode") || "en"

  useEffect(() => {
      fetchLanguage();
      const linkTag = document.getElementById('bootstrap_css');
      document.documentElement.dir =
          currentLang.toLowerCase() == "ar" ? "rtl" : "ltr";
      if (currentLang.toLowerCase() == "ar") {
          document.documentElement.setAttribute('lang', 'ar');
          linkTag?.setAttribute(
              'href',
              `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`
          );
      }

    //   document.title = t("title");
    document.title = "Gamerji Gaming Portal";
    console.log(localStorage.getItem("GJLang"))
    if(currentLang == "undefined" || currentLang == undefined)
        setSelectedLanguage("English")
    else
    setSelectedLanguage(localStorage.getItem("GJLang") || Cookies.get("GJLang") || "English")
  }, [currentLang]);

  const fetchLanguage = async() => {
      const body = {
          companyId: process.env.REACT_APP_COMPANY_ID,
      };
      try {
          const res = await getLanguage(body);
          // console.log("fetchLanguage",res?.data?.data)
          setLanguages(res?.data ?.data);
      } catch (error) {
          console.log(error);
      }
  };

  return ( <nav class = "navbar navbar-expand-lg fixed-top text-white"
          style = {
              { color: "#fff", backgroundColor: '#070B28' }
          }>
          <div class = "container d-flex " >
          <Link class = "navbar-brand" to = { `/` } > < img src = { Logo } class = "navbar-logo" alt = "" /> </Link> {
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                 <span class="navbar-toggler-icon"></span>
                 </button>
      } { /* <div class="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02"> */ } {
          /* <ul class="navbar-nav main-navbar-nav mb-lg-0 d-flex align-items-center">
                      <li class="nav-item">
                          <span class={`nav-link ${active == 5 ? "active": ""}`} aria-current="page"  style={{color:"#fff", cursor:'pointer'}} onClick={()=> {
                           
                            navigate('/')
                         }}>Home</span>
                      </li>
                    </ul> */
      } <div class = "dropdown customDropdown ms-xl-5 ms-3" >
      <span class = "dropdown-toggle" href="#"
  role="button"
  data-bs-toggle = "dropdown"
  aria-expanded = "false" > { selectedLanguage } </span>

  <ul class = "dropdown-menu" > {
          languages?.map((ele, ind) => ( <li key = { ind } >
              <span class = "dropdown-item"
              onClick = {
                  (e) => {
                      i18next.changeLanguage(ele?.code?.toLowerCase());
                      localStorage.setItem(
                          "GJLang",
                          ele.name
                      );
                      localStorage.setItem('GJLangCode', ele?.code?.toLowerCase())
                      Cookies.set('GJLang', ele.name)
                      Cookies.set('GJLangCode', ele?.code ?.toLowerCase())

                      // const head = document.head;
                      // const link = document.createElement("link");
                      // link.type = "text/css";
                      // link.rel = "stylesheet";

                      // // Set the href based on the language
                      // link.href =
                      //   currentLang === "ar"
                      //     ? `"%PUBLIC_URL%/assets/css/bootstrap.rtl.min.css`
                      //     : `"%PUBLIC_URL%/assets/css/bootstrap.min.css`;

                      // // Clear existing stylesheets, if any
                      // const existingLinks = head.querySelectorAll(
                      //   'link[data-type="bootstrap"]'
                      // );
                      // existingLinks.forEach((existingLink) =>
                      //   existingLink.remove()
                      // );

                      // // Add the new stylesheet link
                      // link.dataset.type = "bootstrap";
                      // head.appendChild(link);
                      // console.log(`"%PUBLIC_URL%/assets/css/bootstrap.min.css`, `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`)
                      eventTracking(events.CHANGE_LANGUAGE,{
                        GJ_LanguageName: ele?.code ?.toLowerCase()
                      });
                      window.location.href = window.location.pathname
                  }
              }> { ele.name } </span> </li >
          ))
      } </ul> </div > { /* </div> */ } </div> </nav >
)
}

export default Header