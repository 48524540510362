import instance from "./axios";
import instanceTokenLess from "./tokenlessAxios";
import instancewithoutcredentials from "./withoutcredentials";
import { func } from "./logFunc";

import { routesBackend } from "./routesBackend";
import { url } from "./url";

export const getCountryList = (data) => {
  return instance
    .post(`${url.publicUrl}${routesBackend.countries}`, data)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};
export const requestGoogleLogin = (data) => {
  try {
    return instance
      .post(`${url.authUrl}${routesBackend.googleLogin}`, data)
      .then((res) => {
        return res;
      });
  // return false
} catch (error) {
  // Handle errors here
  // throw error; // You can choose to throw the error or handle it differently
  return error;
}
};
export const userRequestOTP = (data) => {
  try {
    return instance
      .post(`${url.apiUrl}${routesBackend.login}`, data)
      .then((res) => {
        return res;
      });
    } catch (error) {
      // Handle errors here
      // throw error; // You can choose to throw the error or handle it differently
      return error;
    }
};
export const userRequestEmailOTP = (data) => {
  try {
    return instance
      .post(`${url.apiUrl}${routesBackend.emailLogin}`, data)
      .then((res) => {
        return res;
      });
    } catch (error) {
      // Handle errors here
      // throw error; // You can choose to throw the error or handle it differently
      return error;
    }
};
export const userRequestEmaiLoginlOTP = (data) => {
  try {
    return instance
      .post(`${url.apiUrl}${routesBackend.emailLogin2}`, data)
      .then((res) => {
        return res;
      });
    } catch (error) {
      // Handle errors here
      // throw error; // You can choose to throw the error or handle it differently
      return error;
    }
};
export const howToJoinGame = (data) => {
  try {
    return instance
      .post(`${url.apiUrl}${routesBackend.howToJoin}`, data)
      .then((res) => {
        return res;
      });
  } catch (error) {
    // Handle errors here
    // throw error; // You can choose to throw the error or handle it differently
    return error;
  }
};
export const getUserInGameName = async (data) => {
  try {
    const response = await instance.post(
      `${url.saasApiUrl}${routesBackend.getInGameName}`,
      data
    );


    localStorage.setItem("userInGameNames", JSON.stringify(response.data));

    return response;
  } catch (error) {
    // Handle errors here
    // throw error; // You can choose to throw the error or handle it differently
    return error;
  }
};
// export const getUserInGameName = (data) => {
//   return instance.post(`${url.saasApiUrl}${routesBackend.getInGameName}`,data)
//     .then((res) => {
//       return res;
//     });
// };

export const addUserInGameName = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.addInGameName}`, data)
    .then((res) => {
      return res;
    });
};
export const updateDobState = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.updateDobState}`, data)
    .then((res) => {
      return res;
    });
};
export const unsubscribePlan = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.unsubscribe}`, data)
    .then((res) => {
      return res;
    });
};

export const accountCheckEmail = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.accountCheckEmail}`, data)
    .then((res) => {
      return res;
    });
};

// export const getGamesDetails = (id) => {
//   return instance
//     .get(`${url.gamesUrl}${routesBackend.get}${id}`)
//     .then((res) => {
//       return res;
//     });
// };
export const howToPlay = (data) => {
  return instance
    .post(`${url.accountUrl}${routesBackend.howToPlay}`, data)
    .then((res) => {
      return res;
    });
};

export const getAllFreeAvatar = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.avatarFree}`, data)
    .then((res) => {
      return res;
    });
};

export const userRequestOTPSignUp = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.signup}`, data)
    .then((res) => {
      return res;
    });
};
export const userRequestEmailOTPSignUp = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.emailLogin}`, data)
    .then((res) => {
      return res;
    });
};

export const allGames = (data) => {
  return instance
    .post(`${url.gamesUrl}${routesBackend.list}`, data)
    .then((res) => {
      return res;
    });
};

export const gameTypes = (data) => {
  return instance
    .post(`${url.gamesTypesUrl}${routesBackend.list}`, data)
    .then((res) => {
      return res;
    });
};

export const getBanners = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.banners}`, data)
    .then((res) => {
      return res;
    });
};

export const getTournament = (data) => {
  return instance
    .post(`${url.saasApiD11Url}${routesBackend.tournamentList}`, data)
    .then((res) => {
      return res;
    });
};

export const getHtml5Settings = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.html5SettingsList}`, data)
    .then((res) => {
      return res;
    });
};

export const getGameList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.gameList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAccountProfile = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.accountProfile}`, data)
    .then((res) => {
      return res;
    });
};

export const getGameTypeList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.gameTypeList}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const requestApplySignupCode = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.applycode}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const requestUpdateProfile = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.createProfile}`, data)
    .then((res) => {
      console.log(res)
      return res;
    })
    .catch((err) => {
      console.log(err)
      return err;
    });
};

export const requestTournamentsList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.tournamentsList}`, data)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};
export const getLeaderboardTodayList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.leaderboardToday}`, data)
    .then((res) => {
      return res;
    });
};

export const getLeaderboardWeeklyList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.leaderboardWeekly}`, data)
    .then((res) => {
      return res;
    });
};

export const getLeaderboardMonthlyList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.leaderboardMonthly}`, data)
    .then((res) => {
      return res;
    });
};

export const getLeaderboardAllOverList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.leaderboardAllOver}`, data)
    .then((res) => {
      return res;
    });
};

export const userProfileList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.userProfileList}`, data)
    .then((res) => {
      return res;
    });
};

export const blogList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.blogList}`, data)
    .then((res) => {
      return res;
    });
};

export const liveStreamingsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.liveStreamingsList}`, data)
    .then((res) => {
      return res;
    });
};

export const scrimListByUserId = (data) => {
  return instance
    .post(`${url.saasApiUrlV2}${routesBackend.scrimListByUserId}`, data)
    .then((res) => {
      return res;
    });
};

export const walletUsageLimit = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.walletUsageLimit}`, data)
    .then((res) => {
      return res;
    });
};

export const gamerjiTournamentJoin = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.gamerjiTournamentJoin}`, data)
    .then((res) => {
      return res;
    });
};

export const gamerjiTournamentGet = (data, id) => {
  return instance
    .post(
      `${url.saasApiUrl}${routesBackend.gamerjiTournamentGet}${"/" + id}`,
      data
    )
    .then((res) => {
      return res;
    });
};
export const gamerjiTournamentGetSummery = (data, id) => {
  return instance
    .post(
      `${url.saasApiD11Url}${routesBackend.gamerjiTournamentGetSummery}${"/" + id}`,
      data
    )
    .then((res) => {
      return res;
    });
};
export const gamerjiTournamentRules = (data, id) => {
  return instance
    .post(
      `${url.saasApiD11Url}${routesBackend.getgamerjiTournamentRules}${"/" + id}`,
      data
    )
    .then((res) => {
      return res;
    });
};

export const gamerjiTournamentRoundMatch = (data, id) => {
  return instance
    .post(
      `${url.saasApiD11Url}${routesBackend.gamerjiTournamentRoundMatch}`,
      data
    )
    .then((res) => {
      return res;
    });
};

export const gamerjiTournamentLeaderBoard = (data) => {
  return instance
    .post(`${url.saasApiD11Url}${routesBackend.eventLeaderBoards}`, data)
    .then((res) => {
      return res;
    });
};

export const addContestRate = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.addContestRate}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestRate = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.getContestRate}`, data)
    .then((res) => {
      return res;
    });
};

export const getContest = (data) => {
  return instance
    .post(`${url.saasApiD11Url}${routesBackend.contestList}`, data)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

export const requestWalletUsageLimit = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.walletLimit}`, data)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getGameTypeScrim = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.getGameTypeScrim}`, data)
    .then((res) => {
      return res?.data;
    });
};
export const getScrimGameRules = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.getScrimGameRules}`, data)
    .then((res) => {
      return res?.data;
    });
};
export const createScrim = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.createScrim}`, data)
    .then((res) => {
      return res?.data;
    });
};
export const viewScrimById = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.viewScrimById}`, data)
    .then((res) => {
      return res?.data;
    });
};
export const getStatus = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.getStatus}`, data)
    .then((res) => {
      return res?.data;
    });
};
export const getLeaderBorad = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.getLeaderBorad}`, data)
    .then((res) => {
      return res?.data;
    });
};
export const updateRoomIdandPassword = (body) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.roomIdAndPassword}`, body)
    .then((res) => {
      return res;
    });
};

export const getCustomerCareCategory = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.getCustomerCareCategory}`, data)
    .then((res) => {
      return res;
    });
};

export const getGamesSearch = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.getSearchGame}`, data)
    .then((res) => {
      return res;
    });
};

export const getUserContestList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.userContestList}`, data)
    .then((res) => {
      return res;
    });
};

export const ticketUpload = (data) => {
  let formData = new FormData();
  formData.append("file", data);

  return instance.post(`${url.apiUrl}${routesBackend.ticketUpload}`, formData);
};

export const addTicket = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.ticketCreate}`, data)
    .then((res) => {
      return res;
    });
};

export const getTicket = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.ticketList}`, data)
    .then((res) => {
      return res;
    });
};

export const getJoinUserContestList = (data) => {
  return instance
    .post(`${url.saasApiD11Url}${routesBackend.joinUserContestList}`, data)
    .then((res) => {
      return res;
    });
};

export const getGamerjiTournamentList = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.gamerjiTournamentList}`, data)
    .then((res) => {
      return res;
    });
};

export const getGamerjiTournamentRules = (id) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.gamerjiTournamentRules}/${id}`)
    .then((res) => {
      return res;
    });
};

export const getScrimByUserId = (data) => {
  return instance
    .post(`${url.saasApiD11Url}${routesBackend.scrimByUserId}`, data)
    .then((res) => {
      return res;
    });
};
export const ssUpload = (body, id) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.ssUpload}${id}`, body)
    .then((res) => {
      return res;
    });
};
export const updateWinners = (body) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.updateWinners}`, body)
    .then((res) => {
      return res;
    });
};

export const getScrimById = (body) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.getScrimById}`, body)
    .then((res) => {
      return res;
    });
};

export const updateScrimById = (body) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.updateScrim}`, body)
    .then((res) => {
      return res;
    });
};

export const requestContestJoin = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.joinContest}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestDetails = (id) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.contestDetail}${"/" + id}`)
    .then((res) => {
      return res;
    });
};

export const getContestDetailsInfo = (data, id) => {
  return instance
    .post(
      `${url.saasApiD11Url}${routesBackend.contestInfo}${"/" + id}`,
      data
    )
    .then((res) => {
      return res;
    });
};

export const getContestDetailsRules = (id) => {
  return instance
    .post(
      `${url.saasApiD11Url}${routesBackend.contestRules}${"/" + id}`
    )
    .then((res) => {
      return res;
    });
};

export const getContestUserList = (id, data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.contestUserList}${"/" + id}`, data)
    .then((res) => {
      return res;
    });
};

export const appliedPromoCode = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.applyPromoCode}`, data)
    .then((res) => {
      return res;
    });
};

export const myRewardList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.myRewardList}`, data)
    .then((res) => {
      return res;
    });
};

export const rewardCategoryList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.rewardCategoryList}`, data)
    .then((res) => {
      return res;
    });
};

export const applyReward = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.applyReward}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestViaCode = (code, data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.joinViaInviateCodeContest}${"/" + code}`, data)
    .then((res) => {
      return res;
    });
};

export const getTournamentViaCode = (data) => {
  return instance
    .post(
      `${url.saasApiUrl}${routesBackend.joinViaInviateCodeTournament}`,
      data
    )
    .then((res) => {
      return res;
    });
};
export const getStateList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.stateList}`, data)
    .then((res) => {
      return res;
    });
};
export const getContestByCode = (data, id) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.contestByCode}${id}`, data)
    .then((res) => {
      return res;
    });
};
export const joinScrim = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.joiScrim}`, data)
    .then((res) => {
      return res;
    });
};
export const getScrimUserDetails = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.scrimUserDetails}`, data)
    .then((res) => {
      return res;
    });
};
export const updateScrimRating = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.updateScrimRating}`, data)
    .then((res) => {
      return res;
    });
};
export const getHtml5GameCategoryList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.html5GameCategoryList}`, data)
    .then((res) => {
      return res;
    });
};
export const html5GamesUpdateView = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.html5GamesUpdateView}`, data)
    .then((res) => {
      return res;
    });
};

export const getHtml5GameList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.html5GameList}`, data)
    .then((res) => {
      return res;
    });
};

export const getGamerjiPointCategoryList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.gamerjiPointsCategory}`, data)
    .then((res) => {
      return res;
    });
};

export const getGamerjiPointListByCategory = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.gamerjiPointList}`, data)
    .then((res) => {
      return res;
    });
};

export const getPrivacyPolicy = (data) => {
  return instance
    .post(`${url.publicUrl}${routesBackend.privacyPolicy}`, data)
    .then((res) => {
      return res;
    });
};

export const getTermsOfUse = (data) => {
  return instance
    .post(`${url.publicUrl}${routesBackend.termsOfUse}`, data)
    .then((res) => {
      return res;
    });
};

export const followUser = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.followUser}`, data)
    .then((res) => {
      return res;
    });
};

export const getCoinStoreList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.xsollaList}`, data)
    .then((res) => {
      return res;
    });
};
export const getCoinStoreListIndia = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.coinStoreList}`, data)
    .then((res) => {
      return res;
    });
};

export const payUHash = (data) => {
  // console.log(`${url.gamesTypesUrl}${routes.list}`)
  return instance
    .post(`${url.apiUrlWebapp}${routesBackend.payUHash}`, data)
    .then((res) => {
      return res;
    });
};

export const payUWebhook = (data) => {
  // console.log(`${url.gamesTypesUrl}${routes.list}`)
  return instance
    .post(`${url.apiUrlWebapp}${routesBackend.payUWebhook}`, data)
    .then((res) => {
      return res;
    });
};


export const createPayPayment = (data) => {
  // console.log(`${url.gamesTypesUrl}${routes.list}`)
  return instance
    .post(`${url.apiUrlWebapp}${routesBackend.createPayPayment}`, data)
    .then((res) => {
      return res;
    });
};

export const getTransactionList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.transactionList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAvatarCategoryList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.avatarList}`, data)
    .then((res) => {
      return res;
    });
};

export const buyAvatar = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.buyAvatar}`, data)
    .then((res) => {
      return res;
    });
};

export const onCreateScrim = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.joinScrim}`, data)
    .then((res) => {
      return res;
    });
};

export const getAds = (data) => {
  return instance
    .post(`${url.apiUrlV2}${routesBackend.getAds}`, data)
    .then((res) => {
      return res;
    });
};

export const updateCoin = (data) => {
  return instance
    .post(`${url.apiUrlV2}${routesBackend.updateCoin}`, data)
    .then((res) => {
      return res;
    });
};

export const getDailyReward = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.dailyRewardsList}`, data)
    .then((res) => {
      return res;
    });
};

export const getAccountDailyReward = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.accountDailyRewards}`, data)
    .then((res) => {
      return res;
    });
};
export const getLevelsList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.levelList}`, data)
    .then((res) => {
      return res;
    });
};

export const collegeList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.collegeList}`, data)
    .then((res) => {
      return res;
    });
};

export const getStatistics = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.statistics}`, data)
    .then((res) => {
      return res;
    });
};
export const collectDailyRewards = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.collectDailyLoginReward}`, data)
    .then((res) => {
      return res;
    });
};
export const isUserPremium = (id) => {
  //http://localhost:9155/
  return instance
    .post(`${url.saasApiUrlV2}${routesBackend.isUserPremium}${id}`)
    .then((res) => {
      return res;
    });
};
export const createXsollaTransaction = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.createXsollaTrns}`, data)
    .then((res) => {
      return res;
    });
};
export const updateXsollaTransaction = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.updateXsollaTrns}`, data)
    .then((res) => {
      return res;
    });
};
export const getStripeToken = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.createPaymentIntent}`, data)
    .then((res) => {
      return res;
    });
};
export const createStripeTransaction = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.createStripeTransaction}`, data)
    .then((res) => {
      return res;
    });
};

export const getContestByGame = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.contestByGame}`, data)
    .then((res) => {
      return res;
    });
};
export const getRankSummeryByGame = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.rankSummeryByGame}`, data)
    .then((res) => {
      return res;
    });
};
export const getGamesDetails1 = (id) => {
  return instance.post(`${url.apiUrl}games/get/${id}`).then((res) => {
    return res;
  });
};
export const getTicketDetails = (id) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.ticketsGet}${id}`)
    .then((res) => {
      return res;
    });
};
export const updateTicketDetails = (id, data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.ticketUpdate}${id}`, data)
    .then((res) => {
      return res;
    });
};
export const getSubscriptionTypeList = (data) => {
  return instance
    .post(`${url.saasApiUrlV2}${routesBackend.getSubscriptionTypeList}`, data)
    .then((res) => {
      return res;
    });
};
export const getTimeSlots = () => {
  //http://localhost:9155/
  return instance
    .post(`${url.saasApiUrlV2}${routesBackend.getTimeSlots}`)
    .then((res) => {
      return res;
    });
};
export const statusUpdate = (body, id) => {
  return instance
    .post(`${url.saasApiUrlV2}${routesBackend.statusUpdate}${id}`, body)
    .then((res) => {
      return res;
    });
};
export const createStripeSubscriptionTransaction = (data) => {
  return instance
    .post(
      `${url.apiUrl}${routesBackend.createStripeSubscriptionTransaction}`,
      data
    )
    .then((res) => {
      return res;
    });
};
export const updateStripeSubscriptionTransaction = (data) => {
  return instance
    .post(
      `${url.apiUrl}${routesBackend.updateStripeSubscriptionTransaction}`,
      data
    )
    .then((res) => {
      return res;
    });
};
export const getHowToCreateScrim = (data) => {
  return instance
    .post(`${url.scrim_url}${routesBackend.getHowToCreateScrim}`, data)
    .then((res) => {
      return res;
    });
};
export const getHeaderApi = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.getHeaderApi}`, data)
    .then((res) => {
      return res;
    });
};
export const getFeaturedTournaments = (data) => {
  return instance
    .post(`${url.saasApiD11Url}${routesBackend.getFeaturedTournament}`, data)
    .then((res) => {
      return res;
    });
};
export const getGameTypeLists = (data) => {
  return instance
    .post(`${url.saasApiD11Url}${routesBackend.getGameTypeList}`, data)
    .then((res) => {
      return res;
    });
};

export const favGameList = (data) => {
  return instance
    .post(`${url.saasApiD11Url}${routesBackend.favGameList}`, data)
    .then((res) => {
      return res;
    });
};
export const collegeuserList = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.collegeUserList}`, data)
    .then((res) => {
      return res;
    });
};
export const getGamePlayed = (data) => {
  console.log("profileData 2",data)
  return instance
    .post(`${url.saasApiD11Url}${routesBackend.getGamePlayed}`, data)
    .then((res) => {
      return res;
    });
};

export const createNewsLetter = (data) => {
  return instanceTokenLess
    .post(`${url.apiUrl}${routesBackend.createNewsLetter}`, data)
    .then((res) => {
      return res;
    });
};

export const createContactUs = (data) => {
  return instanceTokenLess
    .post(`${url.apiUrl}${routesBackend.createContactUs}`, data)
    .then((res) => {
      return res;
    });
};
export const getConstantProfile = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.getConstantProfile}`, data)
    .then((res) => {
      return res;
    });
};
export const createTangerineSubscription = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.createTangerineSubscription}`, data)
    .then((res) => {
      return res;
    });
};
export const getLanguage = (data) => {
  return instance
    .post(`${url.saasApiUrl}${routesBackend.getLanguage}`, data)
    .then((res) => {
      return res;
    });
};
export const createXsollaSubscriptionTransaction = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.createSubXsollaTrns}`, data)
    .then((res) => {
      return res;
    });
};
export const updateXsollaSubscriptionTransaction = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.updateSubXsollaTrns}`, data)
    .then((res) => {
      return res;
    });
};

export const sendOTP = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.sendOTP}`, data)
    .then((res) => {
      return res;
    });
};


export const validateOtp = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.validateOtp}`, data)
    .then((res) => {
      return res;
    });
};

export const bannerAdLog = (data) => {
  return instance
    .post(`${url.apiUrl}${routesBackend.bannerAdLog}`, data)
    .then((res) => {
      return res;
    });
};

export const getExternalGamePBE = (data) => {
  return instance
    .get(`${url.saasApiUrl}${routesBackend.gamePBE}`, data)
    .then((res) => {
      return res;
    });
};

export const getExternalGamePBL = (data) => {
  return instance
    .get(`${url.saasApiUrl}${routesBackend.gamePBL}`, data)
    .then((res) => {
      return res;
    });
};

// export const getSettings = (body) => {
//   return instance
//     .post(`${url.saasApiUrl}${url.v2Url}${routesBackend.getSettings}`, body)
//     .then((res) => {
//       return res;
//     });
// };