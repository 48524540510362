import React, { useEffect, useState } from 'react'
import LandingPage from './LandingPage'
import { url } from '../../Utilities/url'
import axios from 'axios'
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
const TermsAndCondition = () => {
    useEffect(()=>{
        EventTracking(); 
      },[]);
      const EventTracking=()=>{
        eventTracking(events.TERM_N_CONDITION);
      }

    const [data, setData] = useState()
    const fetchData = async()=>{
        try{
            const res = await axios.post(url.apiUrl+'public/page/terms-and-conditions', {
                headers:{
                   
                   'company-code':'GJ'
                }
            })
            setData(res?.data?.item?.description)
        }
        catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        fetchData()
    },[])
  return (
    <LandingPage>
        <div style={{paddingTop:'7rem', paddingBottom:'1rem' , display:'flex',flexDirection:'column',  justifyContent:'center', alignItems:'center', backgroundColor:'var(--content-color)'}}>
            <h4>Terms & Condition</h4>
            <div className='col-10' dangerouslySetInnerHTML={{ __html: data }} style={{color:"var(--color-heading)"}}>

            </div>
        </div>
    </LandingPage>
  )
}

export default TermsAndCondition