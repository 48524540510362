
// export const d11_color = {
   
//     "color-black": "#ffffff",
//     "color-white": "#fff",
  
//     "font-titles": "Oswald Bold",
//     "font-titles-regular": "Oswald Regular Regular",
//     "font-body": "codec_proregular",
//     "font-body-bold": "codec_probold",
//     "font-body-extraBold": "codec_proextrabold",
//     "font-body-heavy": "codec_proheavy",
  
//     /* Modal */
//     "modal-bg": "#13181e",
  
//     /* Custom Colors */
//     "primary-color": "#371B58",
//     "primary-color-dark-blue": "#916BBF",
//     "primary-color-red": "#C996CC",
//     "primary-color-emphasis": "#FFC609",
//     "primary-color-dark": "#C996CC",
//     "secondary-color": "#D9D9D9",
//     "secondary-color-emphasis": "#D9D9D9",
//     "secondary-color-emphasis-rgb": "#4C555F",
//     "accent-color": "#C996CC",
//     "accent-color-2": "#C996CC",
//     "dashboard-bg": "#C996CC",
//     "body-color": "#707070",
//     "dark-color": "#070B28",
//     "info-color": "#00c2ff",
//     "gray-color": "#a6a6a6",
//     "golden-color": "#c5a260",
//     "golden-color-emphasis": "#cc9747",
//     "color-black-2": "#1C1C1C",
  
//     /* Bootstrap Variable Override */
  
//     "bs-secondary-bg-subtle": "#323d48",
//     "bs-success-rgb": "7, 188, 12",
//     "disabled:" :"#3e4b5a79",
//     "navbar-top-height": "5.25rem",
//     "premium-color":"#1C1C1C",
//     "color-gradient-1":"#371B58",
//     "color-gradient-2":"#7858A6",
//     "content-color": "#180A0A"
// }

// export const d11_color = {
   
//     "color-black": "#ffffff",
//     "color-white": "#fff",
  
//     "font-titles": "Oswald Bold",
//     "font-titles-regular": "Oswald Regular Regular",
//     "font-body": "codec_proregular",
//     "font-body-bold": "codec_probold",
//     "font-body-extraBold": "codec_proextrabold",
//     "font-body-heavy": "codec_proheavy",
  
//     /* Modal */
//     "modal-bg": "#13181e",
  
//     /* Custom Colors */
//     "primary-color": "#F2613F",
//     "primary-color-dark-blue": "#F2613F",
//     "primary-color-red": "#9B3922",
//     "primary-color-emphasis": "#FFC609",
//     "primary-color-dark": "#9B3922",
//     "secondary-color": "#D9D9D9",
//     "secondary-color-emphasis": "#D9D9D9",
//     "secondary-color-emphasis-rgb": "#4C555F",
//     "accent-color": "#F2613F",
//     "accent-color-2": "#9B3922",
//     "dashboard-bg": "#9B3922",
//     "body-color": "#ffffff",
//     "dark-color": "#F2613F",
//     "info-color": "#00c2ff",
//     "gray-color": "#a6a6a6",
//     "golden-color": "#c5a260",
//     "golden-color-emphasis": "#cc9747",
//     "color-black-2": "#1C1C1C",
  
//     /* Bootstrap Variable Override */
  
//     "bs-secondary-bg-subtle": "#323d48",
//     "bs-success-rgb": "7, 188, 12",
//     "disabled:" :"#3e4b5a79",
//     "navbar-top-height": "5.25rem",
//     "premium-color":"#1C1C1C",
//     "color-gradient-1":"#F2613F",
//     "color-gradient-2":"#9B3922",
//     "content-color": "#0C0C0C"
// }
export const gamerji = {
   
    "color-black": "#000000",
    "color-white": "#ffffff",
  "color-heading":"#070B28",
  "color-white-opacity": "#070B28",
    "font-titles": "Oswald Bold",
    "font-titles-regular": "Oswald Regular Regular",
    "font-body": "codec_proregular",
    "font-body-bold": "codec_probold",
    "font-body-extraBold": "codec_proextrabold",
    "font-body-heavy": "codec_proheavy",
  
    /* Modal */
    "modal-bg": "#13181e",
  
    /* Custom Colors */
    "primary-color": "#F92C2C",
    "primary-color-dark-blue": "#070B28",
    "primary-color-red": "#F92C2C",
    "primary-color-emphasis": "#FFC609",
    "primary-color-dark": "#FFC609",
    "secondary-color": "#D9D9D9",
    "secondary-color-emphasis": "#D9D9D9",
    "secondary-color-emphasis-rgb": "#4C555F",
    "accent-color": "#070B28",
    "accent-color-2": "#FFC609",
    "dashboard-bg": "#FFC609",
    "body-color": "#707070",
    "dark-color": "#070B28",
    "info-color": "#00c2ff",
    "gray-color": "#a6a6a6",
    "golden-color": "#c5a260",
    "golden-color-emphasis": "#cc9747",
    "color-black-2": "#1C1C1C",
  
    /* Bootstrap Variable Override */
  
    "bs-secondary-bg-subtle": "#323d48",
    "bs-success-rgb": "7, 188, 12",
    "disabled:" :"#3e4b5a79",
    "navbar-top-height": "5.25rem",
    "premium-color":"#1C1C1C",
    "color-gradient-1":"#010922",
    "color-gradient-2":"#1e2e50",
    "content-color": "#fff",
    "pagination-color":"#ffffff69"

}
export const d11_color = {
   
    "color-black": "#000000",
    "color-white": "#ffffff",
    "color-heading":"#ffffff",
    "color-white-opacity": "#ffffff69",
    "font-titles": "Oswald Bold",
    "font-titles-regular": "Oswald Regular Regular",
    "font-body": "codec_proregular",
    "font-body-bold": "codec_probold",
    "font-body-extraBold": "codec_proextrabold",
    "font-body-heavy": "codec_proheavy",
  
    /* Modal */
    "modal-bg": "#131E3A",
  
    /* Custom Colors */
    "primary-color": "#F92C2C",
    "primary-color-dark-blue": "#070B28",
    "primary-color-red": "#F92C2C",
    "primary-color-emphasis": "#FFC609",
    "primary-color-dark": "#FFC609",
    "secondary-color": "#D9D9D9",
    "secondary-color-emphasis": "#D9D9D9",
    "secondary-color-emphasis-rgb": "#4C555F",
    "accent-color": "#070B28",
    "accent-color-2": "#ffb100",
    "dashboard-bg": "#ffffff69",
    "body-color": "#707070",
    "dark-color": "#070B28",
    "info-color": "#00c2ff",
    "gray-color": "#a6a6a6",
    "golden-color": "#c5a260",
    "golden-color-emphasis": "#cc9747",
    "color-black-2": "#1C1C1C",
  
    /* Bootstrap Variable Override */
  
    "bs-secondary-bg-subtle": "#323d48",
    "bs-success-rgb": "7, 188, 12",
    "disabled:" :"#3e4b5a79",
    "navbar-top-height": "5.25rem",
    "premium-color":"#1C1C1C",
    "color-gradient-1":"#070B28",
    "color-gradient-2":"#070B28",
    "content-color": "#131E3A",
    "pagination-color":"#ffffff69"

}