import React, { useEffect, useRef, useState } from 'react'
import CrossIcon from '../../assets/images/cross-icon-svg.svg'
import premiumIcon from '../../assets/new-images/gamerji-coin.png'
import TickIconPurple from '../../assets/images/tick-icon-svg-purple.svg'
// import TickIconWhite from '../../assets/images/tick-icon-svg-white.svg'
import { toast } from 'react-toastify'
import { createXsollaSubscriptionTransaction, getSubscriptionTypeList, payUHash, unsubscribePlan } from '../../Utilities/controller'
// import Master from '../../assets/images/dGames-master.png'
// import Champion from '../../assets/images/dGames-champion.png'
// import ChampIcon from '../../assets/images/championIcon.png'
// import MasterIcon from '../../assets/images/grandMasterIcon.png'
import SuccessPop from './SuccessPop'
// import PaymentModal from '../HomePage/CoinStore/PaymentModal'
// import Loader from '../Common/Loader'
import { useProfileDataContext } from '../HomePage/ProfileContext'
import { useTranslation } from 'react-i18next'
import Loader from "../Common/Loader";
import EmailVerificationModal from "../HomePage/EmailVerification/EmailVerificationModal";

import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
import PayuModal from '../HomePage/CoinStore/PayuModal'

const SubscriptionTypeList = () => {
  const [list, setList] = useState([])

  const [loading, setLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const { liteProfile, premiumUser } = useProfileDataContext()
  const { t } = useTranslation();
  const contentRef = useRef(null);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [activeRedeemReward, setActiveRedeemReward] = useState(null);
  const [showPayuForm, setShowPayuForm] = useState(false)
  const [payUData, setPayUData] = useState(null)
  var [newEmail, setNewEmail] = useState(null)
  const [selectedItem, setSelecetedItem] = useState(null)
  useEffect(() => {
    EventTracking();
  }, []);
  const EventTracking = () => {
    eventTracking(events.MENU.PREMIUM);
  }
  const fetchList = async () => {
    // console.log(liteProfile?.country?._id)
    setLoading(true);
    if (liteProfile?.country?._id) {
      let payload = {
        country: liteProfile?.country?._id,
        company: process.env.REACT_APP_COMPANY_ID
      }
      try {
        // alert('jaimin');
        const res = await getSubscriptionTypeList(payload)
        // console.log("subscription list 13", res)
        setList(res?.data?.data?.[0]?.param?.data)
        setLoading(false);
      }
      catch (error) {
        toast.error(error?.response?.data?.errors?.[0]?.msg);
        setLoading(false);
      }
    }
  }

  const handlerBuyPlanVerify = async (packID) => {
    setLoading(true);
    try {
      let payload = {
        subscriptionId: packID,
        isMobile: false,
      };
      const res = await createXsollaSubscriptionTransaction(payload);
      // console.log(res.data)
      if (res.data.success) {
        localStorage.setItem("xsolla-body", JSON.stringify(payload));
        // setToken(data?.data?.token);
        // setLoading(false);
        // console.log('jaimin')
        let url = `https://secure.xsolla.com/paystation4/?token=${res?.data?.data.token}`;
        window.location.href = url;
      } else {
        setLoading(false);
      }
    } catch (error) {
      // console.log(error)
      setLoading(false);
      toast.error(error?.response?.data?.errors[0]?.msg)
    }
  }

  const handlerPayuForm = async (item) => {


    let verifiedEmail = localStorage.getItem('verifiedEmail')
    console.log(">>>>>>>>>>>>handlerPayuForm:: subscription", item)
    if ((liteProfile?.item?.isEmailVerified && liteProfile?.item?.isMobileVerified) || (selectedItem && selectedItem !== null && verifiedEmail)) {
      try {

        let payload = {
          key: process.env.REACT_APP_KEY,
          amount: item?.amount,
          productId: item?._id,
          email: verifiedEmail || liteProfile?.item?.email,
          phone: liteProfile?.item?.phone,
          productInfo: process.env.REACT_APP_SUBSCRIPTION_PRODUCT,
          surl: process.env.REACT_APP_BASE_URL + "api/webapp/payu/payment-status",
          furl: process.env.REACT_APP_BASE_URL + "api/webapp/payu/payment-status",
          curl: process.env.REACT_APP_BASE_URL + "api/webapp/payu/payment-status",
          userId: liteProfile?.item?._id,
          packName: item?.packName,
          platform:"webportal"

        };
        // console.log(payload)
        let res = await payUHash(payload);
        // console.log(res)
        if (res?.data?.success) {
          payload = { ...payload, hash: res?.data?.hash, txnid: res?.data?.transactionID }
          setTimeout(() => {
            setPayUData(payload)
            setShowPayuForm(true)
          }, 500)
          // setPayUData(payload)
          // // console.log("setDat")
          // setShowPayuForm(true)
          // // console.log(payload)
        }
      } catch (error) {

      }
    } else {
      setSelecetedItem(item)
      setEmailVerificationModal(true);
    }
  }




  const handlerBuyPlan = async (packID) => {
    // console.log(liteProfile?.country?._id)
    eventTracking(events.SUBSCRIPTION_PLAN, { GJ_PlanAmount: packID.amount });
    setActiveRedeemReward(packID._id);
    console.log(liteProfile?.country?._id !== "611e04284ac17121fd8b1a54")
    if (liteProfile?.item?.isEmailVerified && liteProfile?.item?.isMobileVerified) {
      if (liteProfile?.country?._id !== "611e04284ac17121fd8b1a54") {
        handlerBuyPlanVerify(packID._id)
      } else {
        handlerPayuForm(packID)
      }

    } else {
      setSelecetedItem(packID)
      setEmailVerificationModal(true);
    }

  }
  const handleUnsubscribePlan = async () => {
    setLoading(true);
    try {
      const res = await unsubscribePlan();
      // console.log(res.data)
      if (res.data.success) {
        window.location.href = "/home"
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
      toast.error(error?.response?.data?.errors[0]?.msg)
    }
  }

  const sendRequest = (res) => {
    console.log("res", res)

    if (res) {
      let item = selectedItem
      setSelecetedItem(item)
      setEmailVerificationModal(false);
      setTimeout(() => {
        if (localStorage.getItem('country') == '611e04284ac17121fd8b1a54')
          handlerPayuForm(item)
        else
          handlerBuyPlanVerify(item._id);
      }, 500)

    }



  };

  useEffect(() => {
    console.log("clled")
    fetchList()
    if (document.documentElement.dir == "rtl" && contentRef?.current) {
      const contentWidth = contentRef?.current?.clientWidth - contentRef?.current?.scrollWidth;
      contentRef?.current.scrollTo({
        left: contentWidth,
        behavior: 'smooth',
      });
    }
    else {
      const contentWidth = contentRef?.current?.scrollWidth - contentRef?.current?.clientWidth;
      contentRef?.current.scrollTo({
        left: contentWidth,
        behavior: 'smooth',
      });
    }

  }, [])


  if (loading) return <Loader />;
  return (
    <div className='content'>
      <div className='inner'>
        <div class="row gy-5">
          <div class="col-12">
            <div class="mb-2 d-flex align-items-center justify-content-between">
              <h2 class="h3 bodyBoldFont mb-0">{t("premium.gamerji_premium")}</h2>
            </div>
            <div class={list?.length > 0 ? "pricingTable" : ""} ref={contentRef}>
              {list?.length > 0 ?
                <table class="table m-0">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">
                        <h4 class="text-dark">{t('tournaments.free')}</h4>
                      </th>
                      <th scope="col">
                        <img class="mb-4 icon-50" src={premiumIcon} alt="" />
                        <h4 class="text-dark">{list?.[0]?.planName}</h4>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td colspan={3}><h5><b>{t("premium.features")}</b></h5></td>
                    </tr>

                    <tr>
                      <td>{t("premium.premium_support")}</td>
                      <td><img src={CrossIcon} alt="" /></td>
                      <td>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.2793 12.4802L9.75962 16.8002L18.7193 8.16016" stroke="var(--color-heading)" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                      </td>
                    </tr>

                    <tr>
                      <td>{t("premium.exclusive_skins_avatars")}</td>
                      <td><img src={CrossIcon} alt="" /></td>
                      <td>   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.2793 12.4802L9.75962 16.8002L18.7193 8.16016" stroke="var(--color-heading)" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/>
</svg></td>
                    </tr>

                    <tr>
                      <td>{t("premium.subscription_badge")}</td>
                      <td><img src={CrossIcon} alt="" /></td>
                      <td>   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.2793 12.4802L9.75962 16.8002L18.7193 8.16016" stroke="var(--color-heading)" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/>
</svg></td>
                    </tr>


                    <tr>
                      <td>{t("premium.ad_free_experience")}</td>
                      <td><img src={CrossIcon} alt="" /></td>
                      <td>   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.2793 12.4802L9.75962 16.8002L18.7193 8.16016" stroke="var(--color-heading)" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/>
</svg></td>
                    </tr>
                    <tr>
                      <td>{t("premium.joining")}</td>
                      <td>{t("premium.daily1")}</td>
                      <td><b>{t("premium.unlimited_free_joining")}</b></td>
                    </tr>


                    <tr>
                      <td>{t("premium.private_contest_hosting")}</td>
                      <td><img src={CrossIcon} alt="" /></td>
                      <td>   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.2793 12.4802L9.75962 16.8002L18.7193 8.16016" stroke="var(--color-heading)" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/>
</svg></td>
                    </tr>
                    <tr>
                      <td>{t("premium.statistic")}</td>
                      <td>{t("premium.basic_statistics")}</td>
                      <td><b>{t("premium.advance_statistics")}</b></td>

                    </tr>
                    <tr>
                      <td>{t("premium.leaderboard_highlight")}</td>
                      <td><img src={CrossIcon} alt="" /></td>
                      <td>   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.2793 12.4802L9.75962 16.8002L18.7193 8.16016" stroke="var(--color-heading)" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/>
</svg></td>
                    </tr>
                    <tr>
                      <td>{t("premium.prize_pool_multipler")}</td>
                      <td>1x</td>
                      <td>{list?.[0]?.multiplier || 1.5}x</td>

                      {/* <td class="text-primary bodyHeavyFont">{list?.[2]?.multiplier || 2}x</td> */}
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      {
                        premiumUser == false || localStorage.getItem("planName") == ("Free" || "undefined") ?
                          <td>
                            <div class="row justify-content-center text-dark">
                              <div>
                                <ul class="nav nav-pills  d-flex justify-content-between sub-nav">
                                  <div className='col-12' style={{ textAlign: 'center' }}>
                                    <span class="btn btn-outline-golden text-dark free-button" style={{ cursor: 'initial' }}>{t('tournaments.free')}</span>
                                  </div>
                                </ul>

                                <div className='col-12' style={{ textAlign: 'center' }}>
                                  <span class="btn btn-outline-golden active-button" style={{ cursor: 'initial' }}> {t("premium.currently_active")}</span>
                                </div>
                              </div>
                            </div>
                          </td> :
                          <td>
                            <div class="row justify-content-center text-dark">
                              <div>
                                <ul class="nav nav-pills  d-flex justify-content-between sub-nav">
                                  <div className='col-12' style={{ textAlign: 'center' }}>
                                    <span class="btn btn-outline-golden text-dark free-button" style={{ cursor: 'initial' }}>{t('tournaments.free')}</span>
                                  </div>
                                </ul>
                              </div>
                            </div>
                          </td>
                      }
                      {/* {console.log(premiumUser, premiumUser?.isPremium === false)} */}
                      {
                        premiumUser === false ?
                          <td>
                            <div class="row justify-content-center text-dark">
                              <div>
                                <ul
                                  class="nav nav-pills  d-flex justify-content-between auth-nav"
                                  id="myTab"
                                  role="tablist"
                                >
                                  {
                                    list?.[0]?.packs.map((value, index) => (

                                      <li class="nav-item" role="presentation" style={{ width: `${100 / list?.[0]?.packs.length}%` }}>
                                        <button
                                          className={` ${activeIndex === index ? "nav-link active" : "nav-link"
                                            }`}
                                          // class="nav-link active"
                                          onClick={() => setActiveIndex(index)}
                                          id="prizePool-tab"
                                          data-bs-toggle="tab"
                                          data-bs-target="#prizePool-tab-pane"
                                          type="button"
                                          role="tab"
                                          aria-controls="prizePool-tab-pane"
                                          aria-selected="true"
                                          style={{ width: "100%" }}
                                        >
                                          {value?.packName}
                                        </button>
                                      </li>
                                    ))
                                  }
                                </ul>

                                <div className='col-12' style={{ textAlign: 'center' }}>
                                  <button
                                    style={{ width: "80%" }}
                                    onClick={() => {
                                      handlerBuyPlan(list?.[0]?.packs[activeIndex]);
                                      console.log("list?.[0]?.packs[activeIndex]", list?.[0]?.packs[activeIndex])
                                    }}
                                    className="btn btn-gradient">

                                    {t('premium.buy_for', { amount: `${list?.[0]?.currencyID?.symbol} ${list?.[0]?.packs[activeIndex].amount}` })}

                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                          :
                          <td>
                            <div class="row justify-content-center text-dark">
                              <div>
                                <ul class="nav nav-pills  d-flex justify-content-between sub-nav">
                                  <div className='col-12' style={{ textAlign: 'center' }}>
                                    <span class="btn btn-outline-golden active-button" style={{ cursor: 'initial' }}> {t("premium.currently_active")}</span>
                                  </div>
                                </ul>

                                <div className='col-12' style={{ textAlign: 'center' }}>
                                  <button
                                    style={{ width: "80%" }}
                                    onClick={handleUnsubscribePlan}
                                    className="btn btn-gradient">
                                    {t('Profile.unsubscribe')}
                                  </button>
                                </div>
                              </div>

                            </div>
                          </td>
                      }

                    </tr>
                  </tfoot>
                </table>
                :
                <div class="row justify-content-center text-center">
                  <div className='d-flex'>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}><h3>{t('notFound.no_records_found')}</h3></div>
                  </div>
                </div>
              }
            </div>

          </div>
        </div>
        {
          <SuccessPop />
        }
        {/* {
          showPaymentOption && <PaymentChooseOption showPaymentOption={showPaymentOption} setShowPaymentOption={setShowPaymentOption} list={list?.[activeIndex]} />
        } */}
        {emailVerificationModal && (
          <EmailVerificationModal
            emailVerificationModal={emailVerificationModal}
            setEmailVerificationModal={setEmailVerificationModal}
            sendRequest={sendRequest}
            // setNewEmail={setNewEmail}
            tagMobile={liteProfile?.item?.isMobileVerified ? false : true}
            tagEmail={liteProfile?.item?.isEmailVerified ? false : true}
          />
        )}
        {showPayuForm && <PayuModal showPayuForm={showPayuForm} setShowPayuForm={setShowPayuForm} payload={payUData} />}
      </div>
    </div>
  )
}

export default SubscriptionTypeList
